import * as React from "react"

const Features = () => {
  return (
    <>
      <div id="features" className="app-features-area pt-100 pb-70">
        <div className="container">
          <div className="app-section-title">
          
            <h2>Internationell penningsöverföring på bekvämt sätt</h2>
          </div>

          <div className="row justify-content-center">
            <div
              className="col-lg-3 col-sm-6"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200" 
              data-aos-once="true"
            >
              <div className="single-app-features-box">
                <div className="features-content">
                  <i className="flaticon-return"></i>
                  <h3>Låga priser, enkelt och snabbt</h3>
                  <p>Våra superpriser och låga avgifter är svåra att slå. Du sparar mer hos oss</p>
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-sm-6"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="400" 
              data-aos-once="true"
            >
              <div className="single-app-features-box">
                <div className="features-content">
                  <i className="flaticon-security"></i>
                  <h3>Trygg och säker</h3>
                  <p>Slösa inte tid och skapa din transaktion inom några minuter</p>
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-sm-6"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="600" 
              data-aos-once="true"
            >
              <div className="single-app-features-box">
                <div className="features-content">
                  <i className="flaticon-cogwheel"></i>
                  <h3>Bli kund</h3>
                  <p>Välkommen som ny kund i Amal Expres. Du får hjälp av våra duktiga medarbetare att komma igång med dina transaktioner</p>
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-sm-6"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="800" 
              data-aos-once="true"
            >
              <div className="single-app-features-box">
                <div className="features-content">
                  <i className="flaticon-return"></i>
                  <h3>Inget krångel</h3>
                  <p>Du behöver inte åka någonstans. Skicka med din mobil när som helst, var som helst.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Features
