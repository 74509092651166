import * as React from "react"
import { Link } from "gatsby"

import logo from "../../images/logo/logo1.png"

const Footer = () => {
  return (
    <>
      <div className="app-footer-area bg-color-efedf0 pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="logo-area style2">
                <Link to="/app">
                  <img src={logo} className="logo-1" alt="Image" />
                </Link>

                <p>
                  Amal Express Ekonomisk Förening står under finansinspektionens tillsyn med institutnummer 45509{" "}
                </p>

                <div className="social-links">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="flaticon-facebook-1"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.twitter.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="flaticon-twitter-3"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://google.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="flaticon-google-plus"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://linkedin.com/?lang=en"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="flaticon-linkedin-1"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="footer-widjet style2 pl-30">
                <h3>Om oss</h3>
                <div className="link-list">
                  <ul>
                    <li>
                      <Link to="/about-us">
                        <i className="flaticon-next"></i>Om oss
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact">
                        <i className="flaticon-next"></i>Kundsupport
                      </Link>
                    </li>
                    
                   
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-sm-6">
              <div className="footer-widjet style2">
                <h3>Kundservice</h3>
                <div className="link-list">
                  <ul>
                    <li>
                      <Link to="/terms-conditions">
                        <i className="flaticon-next"></i>Allmänna villkor
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">
                        <i className="flaticon-next"></i>Integritetspolicy
                      </Link>
                    </li>
                   
                   
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="get-in-touch">
                <h3>Get In Touch</h3>
                <ul>
                  <li>
                    <a href="tel:+12023459999">
                      <i className="flaticon-phone-call-2"></i> +46736632349
                    </a>
                  </li>
                  <li>
                    <a href="mailto:support@klane.com">
                      {" "}
                      <i className="flaticon-email"></i> info@amalsend.com
                    </a>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="app-copy-right-area bg-color-efedf0 text-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p>
                © Amalsend{" "}
              
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
